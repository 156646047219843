.counter-wrapper {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  border: 1px solid var(--header-counter);
  padding: 0.5rem 1.25rem 0.5rem 1rem;
}

.counter-text {
  height: 2.125rem;
  transition: height 0.3s ease;
  overflow: hidden;

  &--active {
    height: 1.0625rem;
  }
}

.counter-title {
  color: var(--text);
  font-size: 0.875rem;
  font-weight: 500;
  line-height: 1.2;
  letter-spacing: -0.01em;
  margin: 0;
}

.counter-subtitle {
  color: var(--breadcrumbs);
  font-size: 0.75rem;
  font-weight: 400;
  line-height: 1.25;
  margin: 0.125rem 0 0;
}

@media (max-width: 62rem) {
  .counter-wrapper {
    width: 100%;
    justify-content: center;
    background: var(--vacancy);
    padding: 1.125rem 1rem;
    border: none;

    svg {
      width: 1.25rem;
      height: 1.25rem;
    }
  }

  .counter-text--auto-height {
    height: auto;
  }
}
