.top {
  display: flex;
  align-items: center;
  position: relative;
  padding: 0.9375rem 0 0 2rem;
  max-width: 107.5rem;
  margin: 0 auto 2.1rem;

  &:after {
    content: '';
    position: absolute;
    border-bottom: 1px solid var(--border);
    width: 100%;
    height: 1px;
    bottom: -1rem;
  }

  &--white::after {
    border-color: var(--white);
  }
}

.bottom {
  display: none;
  position: fixed;
  bottom: 0;
  left: 0;
  padding: 0.5rem 0;
  background: var(--white);
  z-index: 10;
}

.header {
  background-color: var(--background-sec);
  padding: 1.5rem 2.5rem 0 6.25rem;
  transition: padding 0.3s ease;
  box-sizing: content-box;

  &--white {
    background-color: transparent;
    color: var(--white);
  }
}

.fixed {
  position: sticky;
  top: 0;
  left: 0;
  right: 0;
  z-index: 11;

  &--opened {
    z-index: 12;
  }
}

.container {
  height: initial;
}

.root {
  transition: 0.2s ease-in-out;
  border: none;
  position: fixed;
  z-index: 1;
  top: 0;
  width: 100vw;
  padding: 0;
  overflow-y: auto;
  background: var(--background);
  height: 100%;

  @supports (height: 100dvh) {
    height: 100dvh;
  }
}

.rootClosing {
  opacity: 0;
}

.withoutLine {
  padding-top: 1rem;
  transition: padding 0.3s ease;
}

.line--white a {
  color: var(--white);

  &:after {
    background: var(--white);
  }
}

.whiteNav {
  border-color: var(--white);

  & > div::before {
    background: var(--white) !important;
  }

  a {
    color: var(--white);
  }
}

.whiteMobileTop {
  background-color: transparent;

  a,
  svg {
    color: var(--white);
  }
}

.whiteGigaChat {
  color: var(--white);
  background-color: transparent;
}

@media (max-width: 116rem) {
  .top {
    padding-left: 0;
    margin-left: 6.25rem;
    margin-right: 2.5rem;
  }
}

@media (max-width: 62rem) {
  .searchBarWrapper {
    z-index: 200;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    height: 0;
  }

  .header {
    padding: 0;
  }

  .desktop {
    display: none;
  }

  .fixed {
    padding-right: 0;
  }

  .mobileLineWrapper .top {
    display: none;
  }

  .bottom {
    display: flex;
    background: var(--aside);
    border-top: 1px solid var(--line-border);
  }

  .top {
    display: none;
  }
}
