@import '../../styles/vars.scss';

.middle {
  position: relative;
  padding-bottom: 2rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  column-gap: 1rem;
  transition: padding 0.3s ease;
  max-width: 107.5rem;
  margin: 0 auto;
}

.rates {
  max-width: 14.5625rem;
  width: 100%;
}

.logo {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  color: var(--contrast);

  svg {
    max-width: 25.3125rem;
    width: 100%;
    height: auto;
    transition: max-width 0.3s ease, height 0.3s ease;
  }

  &--white {
    color: var(--white);
  }
}

.buttons {
  min-width: 21.1875rem;
  display: flex;
  gap: 2.5rem;
  justify-content: flex-end;
  align-items: center;
  font-weight: 500;
  font-size: 1rem;
  line-height: 1.31;
  letter-spacing: -0.03em;
  color: var(--black);
  justify-content: flex-end;

  &--no-flex {
    display: block;
    min-width: auto !important;
    width: max(calc(100% / 6 - 1.25rem), 12.625rem);
  }
}

.auth {
  background: none;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 0.625rem;

  &--white {
    color: var(--white);

    svg {
      color: var(--white);
    }

    path {
      fill: var(--white);
    }
  }
}

.forum {
  display: flex;
  align-items: center;
  gap: 0.625rem;
  color: var(--contrast);

  svg {
    color: var(--text);
  }

  &--white {
    color: var(--white);

    svg {
      color: var(--white);
    }
  }
}

.withoutLine {
  padding-bottom: 1rem;
  transition: padding 0.3s ease;

  .logo {
    display: flex;
    align-items: center;

    svg {
      max-width: 12.5rem;
    }
  }

  .chat {
    border-radius: 0 0 2.3125rem 2.3125rem;
    border-top: none;
  }
}

.whiteGigaChat {
  color: var(--white);
}

dialog.chat {
  top: -1rem;
  right: 5.5625rem;
  left: auto;
}

@media (max-width: 80.625rem) {
  .logo {
    transform: translate(-50%, 14%);

    svg {
      max-width: 16.25rem;
    }
  }
}

@media (max-width: $tablet) {
  .buttons {
    min-width: 15.9375rem;
  }

  dialog.chat {
    right: 0.3125rem;
  }
}
